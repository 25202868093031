import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
    {
        path: "/",
        name: "index",
        component: () => import("../views/HomeView.vue")
    },
    {
        path: "/product/category",
        name: "product_category",
        component: () => import("../views/product/CategoryView.vue")
    },
    {
        path: "/product/lists",
        name: "product_lists",
        component: () => import("../views/product/ListsView.vue")
    },
    {
        path: "/product/detail",
        name: "product_detail",
        component: () => import("../views/product/DetailView.vue")
    },
    {
        path: "/cart/lists",
        name: "cart_lists",
        component: () => import("../views/cart/ListsView.vue")
    },
    {
        path: "/order/checkout",
        name: "order_checkout",
        component: () => import("../views/order/CheckoutView.vue")
    },
    {
        path: "/order/detail",
        name: "order_detail",
        component: () => import("../views/order/DetailView.vue")
    },
    {
        path: "/about",
        name: "about",
        component: () => import("../views/about/AboutView.vue")
    },
    {
        path: "/about/privacy",
        name: "about_privacy",
        component: () => import("../views/about/PrivacyView.vue")
    },
    {
        path: "/about/returns",
        name: "about_returns",
        component: () => import("../views/about/ReturnsView.vue")
    },
    {
        path: "/about/terms",
        name: "about_terms",
        component: () => import("../views/about/TermsView.vue")
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/LoginView.vue')
    },
]

const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    history: createWebHashHistory(),
    routes
})

export default router
